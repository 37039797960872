.customCss {
  max-width: 100%;
  height: auto;
  margin: auto;
  border: 1px solid #ccc;
}

.customCanvasCss {
  background-color: #f5f5f5;
}

.customPrev,
.customNext {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}
