.course-builder {
  /* padding-left: 220px;
  padding-top: 80px; */
  margin: 20px;
  /* width: 100%; */
  height: 100%;
  margin-top: 40px;
}

.course-card {
  flex-basis: 350px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 350px;
}
.image-container {
  height: 207px;
  overflow: hidden;
  position: relative;
}
/* 
.image-container:before {
  position: absolute;
  top: 0;
  content: "UNPUBLISHED";
  z-index: 2;
  opacity: 0.8;
  width: 150px;
  position: absolute;
  background: #ff5722;
  border: 1px solid #000;
  top: 25px;
  left: -38px;
  font-size: 0.5rem;
  text-align: center;
  line-height: 1rem;
  letter-spacing: 1px;
  color: #fff;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
} */
.blurbg {
  background-size: cover !important;
  background-position: center center;
  background-origin: padding-box;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
