/* Sitebuilder.css */


.gjs-pn-panel.gjs-one-bg.gjs-two-color{
  background-color: #CCCCCC;
}
.gjs-pn-commands{
  background-color: #CCCCCC;
}
.gjs-pn-buttons{
  background-color: #CCCCCC;
}
.gjs-pn-btn{
  background-color: #CCCCCC;
  color: #0684FB;

}
.gjs-pn-btn >svg{
  color: #0684FB;

}
img{
  filter: none !important;
}
.gjs-sm-properties {
  background-color: #CCCCCC !important;
  color: #ffffff !important;
}
.gjs-sm-sector{
  background-color: #CCCCCC !important;
}

.gjs-label-wrp{
  background-color: #CCCCCC !important;
}

.gjs-field-wrp{
  background-color: #CCCCCC !important;
  color: white;
}
.gjs-trt-trait{
  background-color: #CCCCCC !important;
  color: #0684FB;
}
.gjs-clm-tags{
  background-color: #CCCCCC !important;
  color: #ffffff !important;
}
#gjs-clm-up{
  color: #ffffff !important;
}

#gjs-clm-tags-field{
  background-color:#737272;
}

.gjs-block .gjs-block-label {
  color: #0b0b0b !important;
}

.gjs-clm-tag{
  background-color: #0684FB !important;
}

.gjs-one-bg {
  background-color: #cccccc;
}
.gjs-four-color-h:hover {
  color:black !important;
  

}
.gjs-four-color-h{
  
  background-color: #cccccc;
}
.gjs-two-color {
  color: #ffffff !important;
}
.gjs-block{
  color: #0684FB;
  background-color: #ffffff !important;

}


.gjs-sm-sector .gjs-sm-properties {
  background-color: #CCCCCC !important;
  color: #ffffff !important;
}

.gjs-sm-sector-title{
  background-color: #CCCCCC !important;
}

.gjs-block-category{
  background-color: #CCCCCC;
  color: #0684FB;
}
.gjs-blocks-c{
  background-color: #CCCCCC;
}

/* Main Container for Sitebuilder */
#editor {
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0; /* Light gray background */
}

/* GrapesJS Panels (Topbar, Layers, Styles, Blocks) */
#gjs-pn-commands {
  background-color: #ee1717 !important; /* Dark background for commands panel */
  color: #ffffff !important; /* White text color */
}

#gjs-pn-views-container {
  background-color: #333333 !important; /* Slightly lighter background for views */
  color: #ffffff !important;
}

/* Blocks Section */
#gjs-blocks {
  background-color: #cccccc !important; /* Darker background for blocks panel */
  padding: 10px !important;
}

#gjs-blocks .gjs-block {
  background-color: #CCCCCC !important; /* Dark gray for block items */
  border: 1px solid #6a6a6a !important; /* Border around block items */
  color: #ffffff !important;
}

#gjs-blocks .gjs-block:hover {
  background-color: #6a6a6a !important; /* Highlight block on hover */
  border-color: #ffffff !important;
}

/* Layer Manager */
#gjs-layers {
  background-color: #CCCCCC !important;
   /* Background for layers panel */
  color: #ffffff !important;
}

#gjs-layers .gjs-layer {
  background-color: #CCCCCC !important; /* Layer items background */
  border: 1px solid #6a6a6a !important;
  color: #ffffff !important;
}

#gjs-layers .gjs-layer:hover {
  background-color: #CCCCCC !important; /* Highlight layer on hover */
}

/* Style Manager */
#gjs-sm-sectors {
  background-color: #353535 !important; /* Background for style manager sectors */
  padding: 10px !important;
}

#gjs-sm-sectors .gjs-sm-sector {
  background-color: #4a4a4a !important; /* Sector header background */
  color: #ffffff !important;
}

/* .gjs-sm-sector .gjs-sm-properties {
  background-color: #3a3a3a !important; 
  color: #ffffff !important;
} */


/* GrapesJS Editor Canvas */
#gjs-cv-canvas {
  background-color: #ffffff !important; /* White background for canvas */
  border: 1px solid #cccccc !important; /* Light gray border around canvas */
}

/* GrapesJS Toolbar */
.gjs-toolbar {
  background-color: #444444 !important; /* Dark background for toolbar */
  color: #ffffff !important;
}

/* Custom Block Example */
.gjs-block-label {
  color: #ffffff !important; /* White text for block labels */
}

/* Modal Styling */
.modal-content {
  background-color: #282828 !important; /* Dark background for modal */
  color: #ffffff !important;
}

.modal-header, .modal-footer {
  background-color: #3a3a3a !important; /* Darker header and footer */
  border-bottom: 1px solid #4a4a4a !important; /* Border for header/footer */
}

/* Back Button Styling */
.back-button {
  color: #ffffff !important; /* White color for back button */
  background-color: #1a1a1a !important; /* Dark background for back button */
  padding: 8px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.back-button:hover {
  background-color: #333333 !important; /* Highlight back button on hover */
}

/* Custom Style for Selected Element */
.gjs-selected {
  outline: 3px solid #0684FB !important; /* Orange outline for selected elements */
}

/* Custom Style for Component Add */
.gjs-cv-canvas .gjs-comp-selected {
  border: 2px dashed #0684FB !important; /* Dashed border for newly added components */
}

/* Additional Customizations */
#panelsTag {
  color: #00ff26 !important; /* Custom color for panels tag */
}

.HoverText {
  color: #ff5722 !important; /* Orange text color for hover hints */
}

/* Style for general text elements */
#gjs-text-component {
  color: #333333 !important; /* Dark text color for text components */
}

/* Ensure custom block label styles apply */
.gjs-block .gjs-block-label {
  color: #080808 !important; /* Ensure block label text is black */
}

/* Adjust the iframe body content inside the canvas */
#gjs-frame {
  background-color: #ffffff !important; /* White background for iframe content */
}

/* Overriding iframe content styling */
#gjs-frame-content {
  background-color: #ffffff !important; /* White background for iframe content */
  color: #000000 !important; /* Black text color inside iframe */
}
