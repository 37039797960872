/* For styling the <Calendar> from react-multi-date-picker */
.calendar-class .rmdp-day {
  width: 35px;
  height: 35px;
}

.calendar-class .rmdp-week {
  margin-right: 20px;
}

.calendar-class .rmdp-week-day {
  font-weight: 600;
}

.calendar-class .rmdp-header-values {
  font-weight: 600;
  color: #0074d9;
  text-transform: uppercase;
  margin-left: 50px;
}
