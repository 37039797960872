body {
  margin: 0;
  font-family: 'Roboto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 3px; /* Vertical scrollbar */
  height: 0.1px; /* Horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #0684fb; /* Vertical scrollbar color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Remove color from horizontal scrollbar */
::-webkit-scrollbar-horizontal {
  background: transparent; /* Make the horizontal scrollbar transparent */
}
