.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  border: 2px solid black;
  transform: translate(-50%, -50%); /* Center the loader */
  
  background: none; /* Optional: add a background color to see it clearly */
  z-index: 9999; /* Ensure it's on top of other content */
}

.loader img {
  transform: scale(0.5); 
}
